<section class="loading-background" title="Carregando..">
  <div class="container-loading">
    <div class="loader-content">
      <span style="color: #E4D2FA; animation-delay: 0.0s !important;" class="icon-loading">◆</span>
      <span style="color: #ADB0FF; animation-delay: 0.2s !important;" class="icon-loading">◆</span>
      <span style="color: #D1F8CD; animation-delay: 0.5s !important;" class="icon-loading">◆</span>
    </div>
  </div>
</section>

<style>
  .loading-background{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.3); z-index: 9999; cursor: wait; display:flex; justify-content: center; align-items: center; }
  .icon-loading{ font-size: 2rem; text-shadow: 4px 3px 5px rgba(0, 0, 0, .5); }
  .container-loading{ display: flex; justify-content: center; align-items: center; position: relative; }
  .loader-content{ display: flex; justify-content: center; align-items: center; gap: 0px 25px; }
  .loader-content span{ width: 30px; height: 30px; animation: up 0.9s infinite cubic-bezier(0.41, 0.02, 0, 1.15); }
  
  @keyframes up {
    0%{ transform: translateY(0px); }
    50%{ transform: translateY(-15px); }
    100%{ transform: translateY(0px); }
  }
</style>